import React, {Component, Fragment} from "react";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";

import {PortfolioList} from '../pages/common'

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Welcome to our Demo',
        description: 'Get ready to take your website to the next level. GOTOVIAR, is specialize in making websites fully VR-proofed for VR browsers and users with VR devices. We bring your website to the Metaverse that will engage and impress your customers. From interactive 3D objects and 360 videos, there\'s something for everyone on our demo. Try our demo by clicking on the button GOTOVIAR and be transported to a world beyond your imagination.',
        buttonText: 'Contact Us',
        buttonLink: 'https://www.gotoviar.com'
    }
]

class HomePortfolio extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    getMenuItems() {
        return PortfolioList.map(({id, label, subMenu}) => ({
            id,
            label,
            subMenu: Object.values(subMenu)
        }))
    }

    render() {
        const menuItems = this.getMenuItems()

        return (
            <div className="full-height bg_image_gtvr">
                {/* bg_image_gtvr bg_image_bluenile */}
                <Helmet pageTitle="Demo"/>

                {/* Start Header Area  */}
                <Header menuItems={menuItems}/>
                {/* End Header Area  */}
                {/* Start Slider Area   */}

                { /* css class name for description className="description"*/}
                {/*style={{ 'position': 'absolute', 'bottom': '0', 'width': '100%', 'height': '100%' }}*/}

                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div
                            className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image"
                            key={index} data-black-overlay="6" style={{ 'min-width': '100vw', 'min-height': '100vh' }}>
                            <div className="container" style={{ 'position': 'absolute', 'bottom': '8vh'}}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ?
                                                <p style={{ 'font-size': 'large', 'color': 'white' }}>{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a
                                                className="btn-default btn-border btn-opacity"
                                                href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}
            </div>
        )
    }
}

export default HomePortfolio;