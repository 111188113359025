import React from "react";

export const PagesDetails = {
    'real-estate': {
        id: 'real-estate',
        label: 'Real Estate',
        subMenu: {
            ['real-estate-architects']: {
                id: 'real-estate-architects',
                label: 'Architects',
                desc: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum',
                blocksData: [
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 1 Architects floor plan.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Floorplan',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 2 Architects villa.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Villa',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 3 Architects interior.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Interior',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 4 Architects garden house.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Garden house',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                        ],
                    },
                    {
                        blockDesc: {
                            title: 'placeholder',
                            description: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum'
                        },
                        blocks: [
                            {
                                image: <img
                                    src="/assets/images/portfolio/Nr 5 architects Floorplan House grond floor.jpg"
                                    alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Floorplan',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 6 architects Villa.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Villa',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 7 architects Interior.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Interior',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 8 architects Gardenhouse.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Garden house',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                        ]
                    }

                ]
            },
            ['real-estate-brokers']: {
                id: 'real-estate-brokers',
                label: 'Brokers',
                desc: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum',
                blocksData: [
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 9 brokers Villa for sale.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Villa for sale',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 10 broker PentHouse for sale.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Penthouse for sale',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 11 broker Commercial for sale.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Office for sale',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 12 brokers New Construction for sale.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'New construction for sale',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },

                        ]
                    }
                ]
            },
        }
    },
    'hospitality': {
        id: 'hospitality',
        label: 'Hospitality',
        subMenu: {
            ['hospitality-hotels']: {
                id: 'hospitality-hotels',
                label: 'Hotels',
                desc: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum',
                blocksData: [
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 13  hotel little hotel room.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Hotel room small',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 14 hotel big hotel room.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Hotel room large',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 15 hotel deluxe hotel room.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Hotel room deluxe',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 16 hotel suite.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Hotel room suite',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                        ]
                    },

                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 17 hotel Little Room.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Hotel room small',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 18 hotel Big Room.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Hotel room large',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 19 hotel Deluxe Room.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Hotel room deluxe',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 20 hotel Suit.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Hotel room suite',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                        ]
                    },

                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 21 hotel Sea view.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Sea view',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 22 hotel City tour.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'City tour',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 23 hotel Coutry impression.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Country tour',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 24 hotel Cruise.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Cruise ship',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                        ]
                    },
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 25 hotel Swimming pool.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Swimming pool',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 26 hotel Restaurant.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Restaurant',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 27 hotel spa.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Spa',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 28 hotel Hotel tour.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Hotel tour',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },

                        ]
                    },
                ]
            },
            ['hospitality-airlines']: {
                id: 'hospitality-airlines',
                label: 'Airlines',
                desc: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum',
                blocksData: [
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 29 Airlines boieng.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Floorplan Boeing',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 30 Airlines AIRBUS A320.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Floorplan Airbus',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 31 Business class.jpg"
                                            alt="React Creative Agency"/>,
                                category: '360 VR Video',
                                title: 'Business class',
                                description: 'You can watch this 360 VR Video for an immersive virtual reality experience.'
                            },
                        ]
                    }
                ]
            },
        }
    },
    'retail': {
        id: 'retail',
        label: 'Retail',
        subMenu: {
            ['retail-car']: {
                id: 'retail-car',
                label: 'Car',
                desc: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum',
                blocksData: [
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 32 cars Range Rover.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Range Rover',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 33 cars porchew 911.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Porsche 911',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 34 cars mini cooper.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Mini Cooper',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 35 cars mercedes c.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Mercedes C',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },

                        ]
                    }
                ]
            },
            ['retail-shops']: {
                id: 'retail-shops',
                label: 'Shops',
                desc: 'lorem ipsum lorem ipsu mlor em ipsumlorem ipsumlorem ip s  umlorem ipsumlorem ip  sum',
                blocksData: [
                    {
                        blocks: [
                            {
                                image: <img src="/assets/images/portfolio/Nr 36 shops phone.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Phone',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 37 shops shoe.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Shoes',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 38 shops watch.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Watch',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                            {
                                image: <img src="/assets/images/portfolio/Nr 39 shops furniture.png"
                                            alt="React Creative Agency"/>,
                                category: '3D Model',
                                title: 'Furniture',
                                description: 'You can watch this 3D model for an immersive virtual reality experience.'
                            },
                        ]
                    }
                ]
            },
        },
    }

}

export const PortfolioList = Object.values(PagesDetails)

export const getMenuItems = (items, currentItemId) => [{
    id: 'home',
    label: 'Home'
}, ...items.filter(i => i.id !== currentItemId)]

export default {}