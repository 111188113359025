import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import { useState } from "react";
import { Widget3DTest } from '../../3d/widget3D';

class Header extends Component {

    /**
     * isXrSupported
     * 
     * @returns {Boolean}
     */

    isXrSupported() {
        console.log('showGoTo3D = ' + process.env.showGoTo3D + ', comp = ' + (process.env.showGoTo3D === true));

        if (this.isXrSupportedWasCalled === false) {
            this.isXrSupportedWasCalled = true;

            if ('xr' in navigator) {
                navigator.xr.isSessionSupported('immersive-vr')
                    .then((isSupported) => {
                        if (isSupported)
                            this.setState({ showButton: 'GoToViaR' });
                        else
                            if (process.env.showGoTo3D == "true")
                                this.setState({ showButton: 'GoTo3D' });
                    });
            }
            else
                if (process.env.showGoTo3D == "true")
                    this.setState({ showButton: 'GoTo3D' });
        }
    }

    renderVRButton() {
        if (this.state.vrMode === false) {
            this.isXrSupported();

            if (this.state.showButton) {
                return (
                    <div className="header-btn" >
                        <a className="btn-default btn-border btn-opacity color-gtvr" href="#">
                            <span id="goto_button" onClick={() => this.setvrMode(true)}>{this.state.showButton}</span>
                        </a>
                    </div>)
            }
            else {
                return null;
            }
        }
        else {
            return <Widget3DTest />
        }
    }

    constructor(props) {
        super(props);
        this.isXrSupportedWasCalled = false;
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
        //const [vrMode, setvrMode] = useState(false);
        this.state = {
            vrMode: false,
            showButton: null
        };
    }

    setvrMode() {
        this.setState({ vrMode: !this.state.vrMode });
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    render() {
        const { menuItems = [] } = this.props

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i) && this.parentElement) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        } else if (logo === 'dark') {
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        } else {
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
        }

        logoUrl = <img src="/assets/images/logo/logo_wit_gotoviar_step_inside.png" alt="Software" />;

        return (
            <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>

                    <div className="header-right">
                        {/*
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                {
                                    menuItems.map(({id, label, subMenu}) => {
                                        return (
                                            <li key={id + label} className="has-droupdown">
                                                {!!subMenu ? <a href='#'>{label}</a> : (<Link to={id}>
                                                    {label}
                                                </Link>)}

                                                {subMenu && (
                                                    <ul className="submenu">
                                                        {subMenu.map(subMenuItem => {
                                                            return (
                                                                <li key={subMenuItem.id + subMenuItem.id}>
                                                                    <Link to={subMenuItem.id}>
                                                                        {subMenuItem.label}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                )}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                        */}

                        {this.renderVRButton()}

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}

export default Header;